<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-select
            v-model="form.logType"
            style="width: 200px"
            placeholder="日志类型"
            mode="multiple"
          >
            <a-select-option :value="0">操作日志</a-select-option>
            <a-select-option :value="1">系统日志</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            v-model="form.appModule"
            style="width: 200px"
            placeholder="模块"
            mode="multiple"
          >
            <a-select-option
              v-for="item in moduleList"
              :key="item"
              :value="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-input v-model="form.operation" placeholder="操作名称" />
        </a-form-model-item>

        <a-form-model-item>
          <a-input v-model="form.userName" placeholder="用户名" />
        </a-form-model-item>

        <a-form-model-item>
          <a-input v-model="form.trackId" placeholder="trackId" />
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            v-model="form.rank"
            style="width: 200px"
            placeholder="级别"
            mode="multiple"
          >
            <a-select-option
              v-for="item in rankList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker v-model="form.logDate" />
        </a-form-model-item>

        <a-space style="margin-top: 4px">
          <a-button type="primary" @click="query">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-space>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="type" title="日志类型">
          <template slot-scope="text">
            <span v-if="text.logType === 0">操作日志</span>
            <span v-if="text.logType === 1">系统日志</span>
          </template>
        </a-table-column>
        <a-table-column
          key="name"
          title="模块名称"
          data-index="appModule"
        ></a-table-column>
        <a-table-column
          key="control-name"
          title="操作名称"
          data-index="operation"
        ></a-table-column>
        <a-table-column key="level" title="级别">
          <template slot-scope="text">
            <DataDictFinder dictType="logRank" :dictValue="text.rank" />
          </template>
        </a-table-column>
        <a-table-column
          key="user"
          title="操作用户"
          data-index="userName"
        ></a-table-column>
        <a-table-column
          key="time"
          title="操作时间"
          data-index="logDate"
        ></a-table-column>
        <a-table-column key="detail" title="详情" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="seeDetail(text)">查看</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      title="日志详情"
      :visible="visible"
      @cancel="handleCancel"
      :width="800"
      :footer="null"
    >
      <a-descriptions :column="2">
        <a-descriptions-item label="日志类型">
          <span v-if="detail.logType === 0">操作日志</span>
          <span v-if="detail.logType === 1">系统日志</span>
        </a-descriptions-item>
        <a-descriptions-item label="模块名称">
          {{ detail.appModule }}
        </a-descriptions-item>
        <a-descriptions-item label="操作名称">
          {{ detail.operation }}
        </a-descriptions-item>
        <a-descriptions-item label="级别">
          <DataDictFinder dictType="logRank" :dictValue="detail.rank" />
        </a-descriptions-item>
        <a-descriptions-item label="操作用户">
          {{ detail.userName }}
        </a-descriptions-item>
        <a-descriptions-item label="时间">
          {{ detail.logDate }}
        </a-descriptions-item>

        <a-descriptions-item label="日志内容" :span="2">
          {{ detail.content }}
        </a-descriptions-item>
        <a-descriptions-item label="trackId" :span="2">
          {{ detail.trackId ? detail.trackId : "--" }}
        </a-descriptions-item>
        <a-descriptions-item
          label="lineList"
          :span="2"
          v-if="Array.isArray(detail.lineList)"
        >
          <div v-html="detail.lineList.join('')"></div>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { fetchModuleList, fetchList } from "@/api/setting/log";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      moduleList: [], // 模块列表

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
      detail: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    rankList() {
      return this.findDataDict("logRank");
    },
  },

  mounted() {
    fetchModuleList({
      aggConds: [
        {
          key: "appModule",
        },
      ],
    }).then((res) => {
      console.log("res", res);
      if (
        res["terms#appModule"] &&
        Array.isArray(res["terms#appModule"].buckets)
      ) {
        this.moduleList = res["terms#appModule"].buckets
          .map((item) => item.key)
          .filter((item) => item && item.trim());
      }
    });

    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize, form } = this;
      const {
        logType,
        appModule,
        operation,
        userName,
        rank,
        logDate,
        trackId,
      } = form;
      const params = [];
      if (Array.isArray(logType)) {
        params.push({
          condName: "logType",
          condValue: logType,
        });
      }
      if (Array.isArray(appModule)) {
        params.push({
          condName: "appModule",
          condValue: appModule,
        });
      }
      if (operation) {
        params.push({
          condName: "operation",
          condValue: [operation],
        });
      }
      if (userName) {
        params.push({
          condName: "userName",
          condValue: [userName],
        });
      }
      if (trackId) {
        params.push({
          condName: "trackId",
          condValue: [trackId],
        });
      }
      if (Array.isArray(rank)) {
        params.push({
          condName: "rank",
          condValue: rank,
        });
      }
      if (Array.isArray(logDate) && logDate.length === 2) {
        params.push({
          condName: "logDate",
          condValue: [
            logDate[0].format("YYYY-MM-DD HH:mm:ss"),
            logDate[1].format("YYYY-MM-DD HH:mm:ss"),
          ],
        });
      }
      fetchList({
        pageNum: current,
        pageSize,
        conds: params,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    seeDetail(text) {
      this.detail = text;
      this.visible = true;
    },

    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style>
</style>